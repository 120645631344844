import React, {useEffect, useState} from 'react';
import {Autocomplete, createFilterOptions, Grid, Paper, TextField, Tooltip, Typography} from "@mui/material";
import "./inputField.scss"
import {editAnswer, getAnswer} from "./answerFunctions";
const filter = createFilterOptions();

const CustomPaper = (props) => {
    return <Paper elevation={8} sx={{backgroundColor: "#5c5c5c"}} {...props} />;
};

function AutocompleteSuggestions({q, sectionId}) {
    const {question, desc, optionsFromApi, placeholder, tooltip} = q.data
    const lsAnswer = getAnswer(sectionId, q.id)
    const [value, setValue] = useState(lsAnswer ? JSON.parse(lsAnswer) : null);
    const [options, setOptions] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        //api should return array of objects contains {id, text} - eg. [{id:1, text:2},...]
        // limit api call on 5 and call api with search param
        fetch(optionsFromApi, {mode: 'cors'}).then(async (res)=>{
            const schools = ((await res.json())['hydra:member']).map((sch)=>{
                return {"text": `${sch.name} ${sch.street} ${sch.town}`,"id":sch.id}
            })

            setOptions(schools)
        }).catch((e)=>{
            console.error(e)
        })
        // console.log("getOptionsFromAPI>>>", optionsFromApi)
    },[])
    useEffect(()=>{
        // console.log(value)
        editAnswer(sectionId, q.id, JSON.stringify(value))
    },[value])
    return (
        <Grid container>
            <Grid item xs={12}><Tooltip title={tooltip}><Typography variant={"h3"}>{question}{q.required ? "*" : ""}</Typography></Tooltip></Grid>
            <Grid item xs={12}>
                <Autocomplete
                    className={"customInputField"}
                    value={value}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({text: newValue});
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({text: newValue.inputValue});
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params); // asyncCallHERE @TODO

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.text);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                text: `Vytvoriť "${inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    autoSelect
                    handleHomeEndKeys
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.text;
                    }}
                    PaperComponent={CustomPaper}
                    renderOption={(props, option) => <li {...props}>{option.text}</li>}
                    sx={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params}  label=""
                                   variant="outlined"
                                   placeholder={placeholder} fullWidth={true} />
                    )}
                />

            </Grid>
        </Grid>
    );
}

export default AutocompleteSuggestions;