import React, {useEffect, useMemo, useState} from 'react';
import {
    Badge, Button, Checkbox, FormControl,
    FormControlLabel,
    FormGroup, FormHelperText,
    Grid, MenuItem, OutlinedInput,
    Radio,
    RadioGroup, Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import parse from 'html-react-parser';
import {editAnswer, getAnswer} from "./answerFunctions";

function Choice({q, sectionId, isError}) {
    const {question, desc, styleTemplate, customStyle, tooltip, type, options, layout, placeholder} = q.data
    const [error, setError] = useState(isError);
    useEffect(()=>{
        setError(isError)
    },[isError])
    return (
        <Grid container>
            {question? <Grid item xs={12}><Tooltip title={tooltip}><Typography color={error?"error":"default"} variant={"h3"}>{question}{q.required ? "*" : ""}{tooltip ? <InfoOutlinedIcon sx={{transform: "translateY(-30%)"}} fontSize={"17px"}/>: <></> }</Typography></Tooltip></Grid>
            :<></>}
                <Grid item container xs={12}>
                {customStyle && styleTemplate?.css ? <style>{styleTemplate?.css}</style>:<></>}
                {customStyle ?
                    <CustomChoiceTypes sid={sectionId} styleTemplate={styleTemplate} options={options} type={type} id={q.id} layout={layout} placeholder={placeholder} required={q.required} error={error}/>:
                    <ChoiceTypes sid={sectionId} options={options} type={type} id={q.id} layout={layout} placeholder={placeholder} required={q.required} error={error} showStar={!question && q.required}/>}

            </Grid>
        </Grid>
    );
}

export default Choice;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            backgroundColor: "#5c5c5c"
        },
    },
};

function CustomChoiceTypes({options, type, id, layout, placeholder, styleTemplate, sid, required, error}){
    const lsAnswer = getAnswer(sid, id);

    const [hoverElement, setHoverElement] = useState(-1);
    const [activeElement, setActiveElement] = useState(lsAnswer !== "" ? options.findIndex((opt)=>opt.text === lsAnswer):-1);

    useEffect(()=>{
        const allOptions = document.getElementById(id).children;
        for (let i=0; i < allOptions.length; ++i) {
            allOptions[i].addEventListener('mouseenter', (opt)=>{
                setHoverElement(i)
            })
            allOptions[i].addEventListener('mouseleave', (opt)=>{
                setHoverElement(-1)
            })
            allOptions[i].addEventListener('click', (opt)=>{
                setActiveElement(i)
            })
        }
    },[])

    useEffect(()=>{
        if(activeElement >= 0){
            editAnswer(sid, id, options[activeElement].text)
        }
    },[activeElement])
    switch (type.toLowerCase()){
        case "radial":
            return (<div id={id} className={`${styleTemplate?.wrapperClasses}`}>
                {options.map((o,i)=>{
                    let toRenderHTML = ""
                        if(i === activeElement){
                            toRenderHTML = styleTemplate?.active.replaceAll("{{optionText}}", o.text).replaceAll("{{optionDesc}}", o.desc)
                        }else if (i === hoverElement){
                            toRenderHTML = styleTemplate?.hover.replaceAll("{{optionText}}", o.text).replaceAll("{{optionDesc}}", o.desc)
                        }else{
                            toRenderHTML = styleTemplate?.default.replaceAll("{{optionText}}", o.text).replaceAll("{{optionDesc}}", o.desc)
                        }
                        return <React.Fragment key={i}>{parse(toRenderHTML)}</React.Fragment>
                    })}
                </div>
            )
        case "checkbox":
            return <ChoiceTypes options={options} type={type} id={id} layout={layout} placeholder={placeholder} sid={sid} required={required} error={error}/>
        case "select":
            return <ChoiceTypes options={options} type={type} id={id} layout={layout} placeholder={placeholder} sid={sid} required={required} error={error}/>
    }
    return <></>
}

function ChoiceTypes({options, type, id, layout, placeholder, sid, required, error, showStar}){
    const lsAnswer = getAnswer(sid, id)
    const [values, setValues] = useState(lsAnswer !== "" ? JSON.parse(lsAnswer): type.toLowerCase()==="checkbox" ? [] :[""]);

    const radialChange = (e) => {
        setValues([e.target.value])
    }

    const checkboxChange = (e) => {
        let newVals = values.filter((val)=>val !== e.target.value)
        if (newVals.length === values.length){
            newVals.push(e.target.value)
        }
        setValues([...newVals])
    }


    useEffect(()=>{
        editAnswer(sid, id, JSON.stringify(values))
    },[values])

    switch (type.toLowerCase()){
        case "radial":
            return (
                <FormControl
                    required={required}
                    variant="standard"
                    sx={{mt: 1}}
                    error={error}
                >
                <RadioGroup
                    name={id}
                    sx={{width: "100%"}}
                    value={values[0]}
                    onChange={radialChange}
                >
                    <Grid container>
                    {options.map((o,i)=>{
                        const nlayout = layout ? layout : {xs: 12, sm: 12, md: 12, lg: 12, xl:12}
                        return <Grid key={i} item xs={nlayout.xs} sm={nlayout.sm} md={nlayout.md} lg={nlayout.lg} xl={nlayout.xl}>
                            <FormControlLabel className={"customRadioStyle"} sx={{display: "block"}} id={o.id} value={o.text} control={<Radio />} label={o.text} />
                        </Grid>
                    })}
                    </Grid>
                </RadioGroup>
                    {error? <FormHelperText>Treba zvoliť aspoň 1 možnosť!</FormHelperText>:<></>}
                </FormControl>
            )
        case "select":
            return (
                <Select
                    displayEmpty
                    input={<OutlinedInput className={"customSelect"} />}
                    renderValue={(selected) => {
                        if(selected)
                            return selected;
                        return placeholder
                    }}
                    MenuProps={MenuProps}
                    fullWidth={true}
                    value={values[0]}
                    onChange={radialChange}

                >
                    <MenuItem disabled value="">
                        <em>{placeholder}</em>
                    </MenuItem>
                    {options.map((o,i) => (
                        <MenuItem
                            key={i}
                            value={o.text}
                        >
                            {o.text}
                        </MenuItem>
                    ))}
                </Select>
            )
        case "checkbox":
            return (
                <FormControl
                    required={required}
                    sx={{mt: 1}}
                    variant="standard"
                    error={error}
                >
                    <FormGroup>
                        <Grid container>
                            {options.map((o,i)=>{
                                const nlayout = layout ? layout : {xs: 12, sm: 12, md: 12, lg: 12, xl:12}
                                return <Grid key={i} item xs={nlayout.xs} sm={nlayout.sm} md={nlayout.md} lg={nlayout.lg} xl={nlayout.xl}>
                                    <FormControlLabel className={"customRadioStyle"} sx={{display: "block"}} id={o.id} value={o.text} control={<Checkbox checked={values.findIndex((val)=>val === o.text) >= 0} name={o.text} onChange={checkboxChange}/>} label={showStar?<><div dangerouslySetInnerHTML={{__html: o.text}}></div>*</>: <div dangerouslySetInnerHTML={{__html: o.text}}></div>} />
                                </Grid>
                            })}
                        </Grid>
                    </FormGroup>
                    {error? <FormHelperText>Treba zvoliť aspoň 1 možnosť!</FormHelperText>:<></>}
                </FormControl>
            )
    }
    return (
        <>
        </>
    )
    return(
        <>

        </>)
}
