import React, {useEffect, useState} from 'react';
import {Grid, useTheme} from "@mui/material";
import HeadingText from "./Questions/HeadingText";
import Html from "./Questions/HTML";
import MarkDown from "./Questions/MarkDown";
import ShortAnswer from "./Questions/ShortAnswer";
import LongAnswer from "./Questions/LongAnswer";
import SplitAnswer from "./Questions/SplitAnswer";
import AutocompleteSuggestions from "./Questions/AutocompleteSuggestions";
import Choice from "./Questions/Choice";
import LinearScale from "./Questions/LinearScale";
import GridChoice from "./Questions/GridChoice";
import DateAndTimeField from "./Questions/DateAndTimeField";
import UploadFileField from "./Questions/UploadFileField";


function GetQuestion({q, sectionId, error}){
    const [isError, setIsError] = useState(error)
    useEffect(()=>{
        setIsError(error)
    },[error])
    switch (q.type.toLowerCase()) {
        case 'headingtext':
            return <HeadingText q={q} sectionId={sectionId} isError={isError}/>
        case 'html':
            return <Html q={q} sectionId={sectionId} isError={isError}/>
        case 'markdown':
            return <MarkDown q={q} sectionId={sectionId} isError={isError}/>
        case 'shortanswer':
            return <ShortAnswer q={q} sectionId={sectionId} isError={isError}/>
        case 'longanswer':
            return <LongAnswer q={q} sectionId={sectionId} isError={isError}/>
        case 'splitanswer':
            return <SplitAnswer q={q} sectionId={sectionId} isError={isError}/>
        case 'autocompletesuggestions':
            return <AutocompleteSuggestions q={q} sectionId={sectionId} isError={isError}/>
        case 'choice':
            return <Choice q={q} sectionId={sectionId} isError={isError}/>
        case 'linearscale':
            return <LinearScale q={q} sectionId={sectionId} isError={isError}/>
        case 'gridchoice':
            return <GridChoice q={q} sectionId={sectionId} isError={isError}/>
        case 'dateandtime':
            return <DateAndTimeField q={q} sectionId={sectionId} isError={isError}/>
        case 'uploadfile':
            return <UploadFileField q={q} sectionId={sectionId} isError={isError}/>
    }
}

function FormQuestions({questions, sectionId, error}) {
    const theme = useTheme()
    const [isError, setIsError] = useState(error)
    useEffect(()=>{
        setIsError(error)
    },[error])
    return (
        <>
            {questions.map((q, i)=>{
                const layout = q.layout ? q.layout : {xs: 12, sm: 12, md: 12, lg: 12, xl:12}
                return <Grid key={i} sx={{
                    [theme.breakpoints.down("xl")]: {display: layout.lg==="none" ? "none" : "block" },
                    [theme.breakpoints.down("lg")]: {display: layout.md==="none" ? "none" : "block" },
                    [theme.breakpoints.down("md")]: {display: layout.sm==="none" ? "none" : "block" },
                    [theme.breakpoints.down("sm")]: {display: layout.xs==="none" ? "none" : "block" }

                }} item xs={layout.xs === "none" ? "auto": layout.xs}
                             sm={layout.sm === "none" ? "auto":layout.sm}
                             md={layout.md === "none" ? "auto":layout.md}
                             lg={layout.lg === "none" ? "auto":layout.lg}
                             xl={layout.xl === "none" ? "auto":layout.xl}>
                    <GetQuestion key={q.id} q={q} sectionId={sectionId} error={isError.findIndex((err)=>(err.sid === sectionId) && (err.qid === q.id)) >=0} />
                </Grid>
            })}

        </>
    );
}

export default FormQuestions;