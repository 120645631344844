import React, {useEffect, useState} from 'react';
import {Badge, Grid, TextField, Tooltip, Typography} from "@mui/material";
import './inputField.scss'
import {editAnswer, getAnswer} from "./answerFunctions";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function ShortAnswer({q, sectionId, isError}) {
    const {question, desc, regexCheck, placeholder, tooltip, type, autocomplete} = q.data
    const regex = new RegExp(regexCheck);
    const [fieldValue, setFieldValue] = useState(getAnswer(sectionId, q.id))
    const [error, setFieldError] = useState(isError)
    const [helperText, setHelperText] = useState("")

    useEffect(()=>{
        setFieldError(isError)
    },[isError])

    const fieldChange = (e) => {
        setFieldValue(e.currentTarget.value);
        if (q.required && e.currentTarget.value === ""){
            setFieldError(true)
        }else{
            setFieldError(false)
        }
    }



    useEffect(()=>{
        if(error){
            if(fieldValue===""){
                setHelperText("Toto pole je povinné!")

            }else if(!regex.test(fieldValue)){
                setHelperText("Zadaj v správnom tvare.")
            }

        }else{
            setHelperText("")
        }
    },[error])

    useEffect(()=>{
        // console.log("NASTAVUJEM>", sectionId, q.id)
        editAnswer(sectionId, q.id, fieldValue)
    },[fieldValue])

    return (
        <>
            <Grid container>
                <Grid item xs={12}><Tooltip title={tooltip}><Typography color={error?"error":"default"} variant={"h3"}>{question}{q.required ? "*" : ""}
                    </Typography></Tooltip></Grid>
                <Grid item xs={12}>
                    <TextField className={"customInputField"}
                               required={q.required}
                               type={type ? type : "text"}
                               id={q.id}
                               label=""
                               variant="outlined"
                               placeholder={placeholder}
                               sx={{width: "100%" }}
                               error={error}
                               value={fieldValue}
                               onChange={fieldChange}
                               onBlur={(e)=>{
                                   if((q.required && fieldValue === "")|| !regex.test(fieldValue)){
                                       setFieldError(true)
                                   }
                               }}
                               helperText={helperText}
                               autoComplete={autocomplete ? autocomplete : "off"}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default ShortAnswer;