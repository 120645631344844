import React, {useEffect, useState} from 'react';
import {editAnswer, getAnswer} from "./answerFunctions";
import {Grid, TextField, Tooltip, Typography} from "@mui/material";

function LongAnswer({q, sectionId, isError}) {
    const {question, desc, regexCheck, placeholder, tooltip, layout, color} = q.data
    const regex = new RegExp(regexCheck);
    const [fieldValue, setFieldValue] = useState(getAnswer(sectionId, q.id))
    const [error, setFieldError] = useState(isError)
    const [helperText, setHelperText] = useState("")
    let newlayout = {rows: 3}
    if (layout?.rows){
        newlayout = layout?.rows
    }

    const fieldChange = (e) => {
        setFieldValue(e.currentTarget.value);
        if (q.required && e.currentTarget.value === ""){
            setFieldError(true)
        }else{
            setFieldError(false)
        }
    }
    useEffect(()=>{
        setFieldError(isError)
    },[isError])

    useEffect(()=>{
        if(error){
            if(fieldValue===""){
                setHelperText("Toto pole je povinné!")

            }else if(!regex.test(fieldValue)){
                setHelperText("Zadaj v správnom tvare.")
            }

        }else{
            setHelperText("")
        }
    },[error])

    useEffect(()=>{
        editAnswer(sectionId, q.id, fieldValue)
    },[fieldValue])

    return (
        <>
            <Grid container>
                <Grid item xs={12}><Tooltip title={tooltip}><Typography color={error?"error":(color ? color : "default")} variant={"h3"}>{question}{q.required ? "*" : ""}</Typography></Tooltip></Grid>
                <Grid item xs={12}>
                    <TextField className={"customInputFieldMultiline"}
                               required={q.required}
                               id={q.id}
                               label=""
                               variant="outlined"
                               placeholder={placeholder}
                               sx={{width: "100%" }}
                               error={error}
                               value={fieldValue}
                               onChange={fieldChange}
                               onBlur={(e)=>{
                                   if((q.required && fieldValue === "") || !regex.test(fieldValue)){
                                       setFieldError(true)
                                   }
                               }}
                               helperText={helperText}
                               autoComplete={"off"}
                               multiline={true}
                               minRows={layout?.rows}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default LongAnswer;