export const editAnswer = (sid, qid, value) => {
    const an = localStorage.getItem('answers')
    let answers = []
    if(an !== null){
        answers = JSON.parse(an)
    }
    const changeIndex = answers.findIndex((a)=>(a.sid === sid) && (a.qid === qid))
    if(changeIndex >= 0){
        answers[changeIndex].answer = value
    }else{
        answers.push({sid, qid, answer: value})
    }
    localStorage.setItem('answers', JSON.stringify(answers))
}
export const getAnswer = (sid, qid) => {
    const an = localStorage.getItem('answers')
    let answers = []
    if(an !== null){
        answers = JSON.parse(an)
    }
    const changeIndex = answers.findIndex((a)=>(a.sid === sid) && (a.qid === qid))
    if(changeIndex >= 0){
        return answers[changeIndex].answer
    }
    return ""

}
