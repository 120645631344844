import './App.css';
import {Container, createTheme, ThemeProvider} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import FormSections from "./components/FormSections";
import form from "./data/form.json"

const theme = createTheme({
    palette: {
        error: {
            main: "#FD5300"
        }
    },
    typography: {
        fontFamily: `${form.settings.fontfamily}, serif`,
        color: "white",
        body1: {
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "white"
        },
        h3: {
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "24px",
            color: "white"
        }
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'dasatoDefault' },
                    style: {
                        textTransform: 'none',
                        color: '#000',
                        borderRadius: "100px",
                        backgroundColor: "#FFA5EB",
                        lineHeight: "24px",
                        fontSize: "18px",
                        fontFamily: `${form.settings.fontfamily}, serif`,
                        fontWeight: "500",
                        padding: "16px 32px",
                        "&:hover": {
                            backgroundColor: "#FFA5EB"
                        }
                    },
                },
            ],
        },

    },
});


function App() {
    const [settings, setSettings] = useState(window.currentForm.settings)
    const [sections, setSections] = useState(window.currentForm.sections)
  const [currentSectionIndex, setCurrentSectionIndex] = useState(localStorage.getItem('sids') ? JSON.parse(localStorage.getItem('sids')).pop()+1 : 0);
    useEffect(() => {
        if(currentSectionIndex < 0 && currentSectionIndex >= form.sections.length){
            setCurrentSectionIndex(0)
        }
    }, [currentSectionIndex]);
    // console.log(Object.keys(settings))
  return (
      <ThemeProvider theme={theme}>
        <div style={{backgroundColor: settings.bgcolor, fontFamily: settings.fontfamily, minHeight: `100vh`, position:"relative", overflow: settings.overflow}} >
            {Object.keys(settings).indexOf(`header-${sections[currentSectionIndex]?.id}`) >= 0 ?
                <Container maxWidth={"100%"} sx={{paddingX: "0 !important"}} dangerouslySetInnerHTML={{__html: settings[`header-${sections[currentSectionIndex]?.id}`]}}></Container>: <></>}
                <Container sx={{maxWidth: settings.maxwidth ? settings.maxwidth :"1200px"}}>
                    <FormSections sections={sections} currentSectionIndex={currentSectionIndex} setCurrentSectionIndex={setCurrentSectionIndex}></FormSections>
                </Container>
            {Object.keys(settings).indexOf(`footer-${sections[currentSectionIndex]?.id}`) >= 0 ?
                <Container maxWidth={"100%"} dangerouslySetInnerHTML={{__html: settings[`footer-${sections[currentSectionIndex]?.id}`]}}></Container>: <></>}
        </div>
      </ThemeProvider>
  );
}

export default App;
