import React from 'react';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

function MarkDown({q}) {
    // console.log(q)
    return (
        <ReactMarkdown children={q.data.text} remarkPlugins={[remarkGfm]} />
    );
}

export default MarkDown;