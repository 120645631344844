import React, {useEffect, useState} from 'react';
import {Grid, TextField, Tooltip, Typography} from "@mui/material";
import {editAnswer, getAnswer} from "./answerFunctions";

function SplitAnswer({q, sectionId, isError}) {
    const {question, desc, join, tooltip, splits} = q.data
    const [error, setError] = useState(isError)

    const storedAnswer = getAnswer(sectionId, q.id).split(join)
    let fields = Array(splits.length).fill().map((c)=>"")
    if(storedAnswer.length > splits.length){
        fields = storedAnswer.slice(0,splits.length-1)
        fields.push(storedAnswer.slice(splits.length-1).join(""))
    }else if (storedAnswer.length > 0){
        fields = storedAnswer
    }
    const [splitAnswers, setSplitAnswers] = useState(fields)

    const setAnswerOnSplit = (index, value) => {
        let oldAnsw = [...splitAnswers]
        oldAnsw[index] = value
        setSplitAnswers([...oldAnsw])
    }
    useEffect(()=>{
        editAnswer(sectionId, q.id, splitAnswers.join(join))
    },[splitAnswers])
    useEffect(()=>{
        setError(isError)
    },[isError])

    return (
        <>
            <Grid container>
                <Grid item><Tooltip title={tooltip}><Typography color={isError?"error":"default"} variant={"h3"}>{question}{q.required ? "*" : ""}</Typography></Tooltip></Grid>
                <Grid item container spacing={"24px"}>
                    {splits.map((s, i)=><Grid key={i} item flexGrow={i < splits.length - 1 ? 0: 1}>
                        <OneField s={s} required={q.required} index={i} setValue={setAnswerOnSplit} values={splitAnswers} isError={error}></OneField>
                    </Grid>)}
                </Grid>
            </Grid>
        </>
    );
}

export default SplitAnswer;


function OneField({s, required, index, setValue, values, isError}){
    const {desc, regexCheck, placeholder, tooltip, layout, autocomplete} = s
    const regex = new RegExp(regexCheck);
    const [fieldValue, setFieldValue] = useState(values[index] ? values[index]: "")
    const [error, setFieldError] = useState(isError)
    const [helperText, setHelperText] = useState("")
    useEffect(()=>{
        if(isError)
            if (required && (fieldValue === "" || fieldValue === undefined)){
                setFieldError(true)
            }else{
                setFieldError(false)
            }
    },[isError])
    let newLay = {width: "100%"}
    if(layout?.width){
       newLay = layout
    }
    const fieldChange = (e) => {
        setValue(index, e.currentTarget.value)
        setFieldValue(e.currentTarget.value)
        if (required && e.currentTarget.value === ""){
            setFieldError(true)
        }else{
            setFieldError(false)
        }
    }
    useEffect(()=>{
        if(error){
            if(values[index]===""){
                setHelperText("Toto pole je povinné!")

            }else if(!regex.test(values[index])){
                setHelperText("Zadaj v správnom tvare.")
            }

        }else{
            setHelperText("")
        }
    },[error])

    return (
        <TextField className={"customInputField"}
                   required={required}
                   id={index.toString()}
                   label=""
                   variant="outlined"
                   placeholder={placeholder}
                   sx={{width: newLay.width}}
                   error={error}
                   value={fieldValue}
                   onChange={fieldChange}
                   onBlur={(e)=>{
                       if((required && values[index] === "") || !regex.test(values[index])){
                           setFieldError(true)
                       }
                   }}
                   helperText={helperText}
                   autoComplete={autocomplete ? autocomplete :"off"}
        />
    )
}